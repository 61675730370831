import { BaseFile } from "./BaseFile";

export class ExportedProductFile extends BaseFile
{    
    brands
    tags
    categories

    constructor(params) {
        super(params)

        const { brands, tags, categories } = params

        this.brands = brands
        this.tags = tags
        this.categories = categories
    }

    serailize() {
        return {
            brand_ids: !!this.brands ? this.brands.map(item => item.id) : null,
            tag_ids: !!this.tags ? this.tags.map(item => item.id) : null,
            category_ids: !!this.categories ? this.categories.map(item => item.id) : null,
        }
    }
}