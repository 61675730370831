<template>
    <div>
        <h2><router-link :to="{ name: 'product' }">สินค้าทั้งหมด</router-link> > ส่งออกข้อมูลสินค้า</h2>
        <v-card class="mt-4">
          <v-card-title>ส่งออกไฟล์</v-card-title>
          
          <v-card-subtitle>
            เนื่องจากการส่งออกไฟล์สินค้า จะมีการประมวลผลข้อมูลที่ใช้ระยะเวลาประมาณหนึ่ง เมื่อประมวลผลเสร็จแล้ว ไฟล์จะพร้อมให้ดาวน์โหลดในหน้านี้ ตรง "ประวัติการส่งออกไฟล์"

            ท่านสามารถส่งออกไฟล์ได้โดยการกดปุ่มด้านล่าง และโปรดเข้ามาตรวจสอบในหน้านี้อีกครั้งในภายหลัง

            ทั้งนี้ ไฟล์จะมีอายุ 2 สัปดาห์ นับจากวันที่ได้รับไฟล์
          </v-card-subtitle>

          <v-card-body>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <delay-autocomplete
                    outlined
                    hide-selected               
                    deletable-chips
                    multiple
                    chips 
                    cache-items
                    v-model="file.brands"             
                    label="แบรนด์"
                    placeholder="พิมพ์เพื่อค้นหาแบรนด์"
                    no-data-text="ไม่พบข้อมูล"
                    :url="'/product/brandList'"
                    dense
                    :lazySearch="true"
                    return-object
                    ref="brandAutocomplete"
                    item-text="name"
                    item-value="id"
                  ></delay-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <delay-autocomplete
                    outlined
                    hide-selected               
                    deletable-chips
                    multiple
                    chips 
                    cache-items
                    v-model="file.categories"             
                    label="หมวดหมู่"
                    placeholder="พิมพ์เพื่อค้นหาหมวดหมู่"
                    no-data-text="ไม่พบข้อมูล"
                    :url="'/product/categoryList'"
                    dense
                    :lazySearch="true"
                    return-object
                    ref="categoryAutocomplete"
                    item-text="name"
                    item-value="id"
                  ></delay-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <delay-autocomplete
                    outlined
                    hide-selected               
                    deletable-chips
                    multiple
                    chips 
                    cache-items
                    v-model="file.tags"             
                    label="แท็ก"
                    placeholder="พิมพ์เพื่อค้นหาแท็ก"
                    no-data-text="ไม่พบข้อมูล"
                    :url="'/product/tagList'"
                    dense
                    :lazySearch="true"
                    return-object
                    ref="tagAutocomplete"
                    item-text="name"
                    item-value="id"
                  ></delay-autocomplete>
                </v-col>
              </v-row>
            </v-container>            
          </v-card-body>

          <v-card-actions>              
              <v-btn
                color="primary"               
                large
                :loading="sendingData"
                @click="submitForm"                
              >
                ส่งออก
              </v-btn>
              <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.go(-1)"
              >
                กลับ
              </v-btn>              
            </v-card-actions>
        </v-card>

        <v-card class="mt-4">
            <v-card-title>ประวัติการส่งออกไฟล์สินค้า</v-card-title>

            <v-card-body>
                <v-simple-table style="width: 100%;">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                สร้างเมื่อ
                            </th>
                            <th class="text-left">
                                อัพเดธล่าสุด
                            </th>
                            <th class="text-left">
                                ไฟล์
                            </th>
                            <th class="text-left">
                                สถานะ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in list"
                            :key="item.id"
                        >
                            <td>{{ item.createdAt }}</td>
                            <td>{{ item.updatedAt }}</td>
                            <td>
                                <template v-if="item.status == 'failed'">
                                    {{ item.message }}
                                </template>
                                <template v-else-if="item.status == 'success'">
                                    <a :href="item.URL">{{ item.name }}</a>
                                </template>
                                <template v-else>
                                    กำลังประมวลผล
                                </template>          
                            </td>
                            <td>{{ item.status }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-card-body>            
        </v-card>
    </div>
</template>
<script>
import { ExportedProductFile } from '@/classes/file/ExportedProductFile'
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import errorParser from '@/plugins/errorparser'
export default {
  data() {
    return {
      list: [],
      file: null,
      sendingData: false,
    }
  },
  async created() {
    if (!this.$store.getters['auth/GET_ABILITY'].can('update', 'Product')) {
      return this.$router.go(-1)
    }

    this.file = new ExportedProductFile({})

    this.$store.commit('app/SET_GLOBAL_LOADING', true)

    try {
      const response = await asyncGet('/product/exportedList')

      this.list = response.map(item => new ExportedProductFile(item))
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }

    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        await asyncPostAsJson('/product/export', this.file.serailize())

        const response = await asyncGet('/product/exportedList')

        this.list = response.map(item => new ExportedProductFile(item))
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.sendingData = false
    },
  },
}
</script>